import { RefundProps, Reservation } from '@/root/shared-types'
import { useCustomRouter, usePrintedTicketData } from '@/root/shared-hooks'
import { ChangeEvent, useState } from 'react'
import { reservationService } from '@/root/shared-api'
import useSWR from 'swr'
import { getFormattedPercent } from '@/root/modules/admaritime/RefundCard/helpers/getFormattedPercent'

interface fetchSailsProps {
  reservationId: number
  sailRefIds: number[]
}

const fetchSails = async ({ reservationId, sailRefIds }: fetchSailsProps) =>
  await reservationService.refundReservationSails(reservationId, sailRefIds)

export const useRefund = (
  reservation: Reservation
): {
  isLoading: boolean
  refundProps?: RefundProps
  error?: unknown
} => {
  const ticketData = usePrintedTicketData(reservation)
  const {
    push,
    query: { reservationToken },
  } = useCustomRouter()
  const [isAgreementAccepted, setIsAgreementAccepted] = useState<boolean>(false)
  const [isSelectedRouteThere, setIsSelectedRouteThere] = useState<boolean>(false)
  const [isSelectedRouteBack, setIsSelectedRouteBack] = useState<boolean>(false)
  const [sailRefIds, setSailRefIds] = useState<number[]>([])
  const [isCancellationInProgress, setIsCancellationInProgress] = useState(false)

  if (ticketData.reservation) {
    const { currency, amount, feeAmount, isRoundTrip, there, back, reservationId } = ticketData.reservation

    const {
      data,
      isLoading,
      error,
      // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useSWR([ticketData.reservation ? 'sails' : null, sailRefIds], () => fetchSails({ reservationId, sailRefIds }), {
      shouldRetryOnError: false,
    })

    const sails = data?.sails
    const isAvailableCancelButton =
      isAgreementAccepted && (isRoundTrip ? isSelectedRouteThere || isSelectedRouteBack : true)
    const totalRefundAmount = sails?.totalRefundAmount || 0
    const formattedPercent = getFormattedPercent({ totalRefundAmount, feeAmount, amount })

    const refundRouteInfoPropsThere = {
      departurePort: there.departurePort,
      arrivalPort: there.arrivalPort,
      arrival: there.arrival,
      departure: there.departure,
      isRoundTrip,
      checked: isSelectedRouteThere,
      disabled: there.isRefund,
      onChange: (event: ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).checked
        if (value) {
          setSailRefIds([...sailRefIds, there.sailRefId])
        } else {
          setSailRefIds(sailRefIds.filter((id) => id !== there.sailRefId))
        }

        setIsSelectedRouteThere(value)
      },
    }
    const refundRouteInfoPropsBack = {
      departurePort: back.departurePort,
      arrivalPort: back.arrivalPort,
      arrival: back.arrival,
      departure: back.departure,
      isRoundTrip,
      checked: isSelectedRouteBack,
      disabled: back.isRefund,
      onChange: (event: ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).checked
        if (value) {
          setSailRefIds([...sailRefIds, back.sailRefId])
        } else {
          setSailRefIds(sailRefIds.filter((id) => id !== back.sailRefId))
        }
        setIsSelectedRouteBack(value)
      },
    }
    const refundAmountInfoProps = {
      amount,
      totalRefundAmount,
      formattedPercent,
      currency,
      isFetching: isLoading,
    }
    const refundAgreementProps = {
      isAgreementAccepted,
      onAcceptAgreement: (event: ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).checked
        setIsAgreementAccepted(value)
      },
    }

    const actionButtonProps = {
      async onKeepTickets() {
        await push(`/ticket/${reservationToken}`)
      },
      async onCancel() {
        setIsCancellationInProgress(true)
        await reservationService.refundReservation(reservationId)
        await push(`/refund/result/${reservationToken}`)
      },
      isAvailableCancelButton,
      isCancellationInProgress,
    }
    if (error) {
      return {
        isLoading,
        error,
      }
    }

    return {
      isLoading: false,
      refundProps: {
        refundRouteInfoPropsThere,
        refundRouteInfoPropsBack,
        refundAmountInfoProps,
        refundAgreementProps,
        actionButtonProps,
        reservationId,
        isRoundTrip,
      },
    }
  }
  return {
    isLoading: ticketData.isLoading,
    error: ticketData.error,
  }
}
