import { FC } from 'react'
import { AppLayout, LoginPage } from '@/root/modules/admaritime'
import { ContentContainer } from '@/root/ui/admaritime'

const RegisterAndLoginPage: FC = () => {
  return (
    <AppLayout>
      <ContentContainer disableBackground className="mb-16 mt-14 px-0.5 xxs:px-2 xl:py-0">
        <LoginPage />
      </ContentContainer>
    </AppLayout>
  )
}

export default RegisterAndLoginPage
