import { FC } from 'react'
import { useRouter } from 'next/router'
import CustomerPageLayout from './CustomerPageLayout'
import { PassengersAndGroups } from '@/root/modules/admaritime'
import { useGetPassengersData } from '@/root/shared-hooks'
import { Loader } from '@/root/ui/shared'
import { ToastContainer } from 'react-toastify'

const CustomerPassengersAndGroupsPage: FC = () => {
  const router = useRouter()
  const showPassengersAndGroups = router.pathname.includes('passengersData')

  const { data, isLoading } = useGetPassengersData()

  if (data) {
    return (
      <>
        <CustomerPageLayout>
          {showPassengersAndGroups && <PassengersAndGroups groups={data.companies} customers={data.passengers} />}
        </CustomerPageLayout>
        <ToastContainer />
      </>
    )
  }
  if (isLoading) {
    return (
      <CustomerPageLayout>
        <Loader />
      </CustomerPageLayout>
    )
  }
}

export default CustomerPassengersAndGroupsPage
