import { formatToFixedNumber } from '@/root/shared-helpers'

interface GetFormattedPercentProps {
  amount: number
  totalRefundAmount: number
  feeAmount: number
  locale?: string
}
export const getFormattedPercent = ({
  amount,
  totalRefundAmount,
  feeAmount,
  locale = 'en',
}: GetFormattedPercentProps) => {
  const percent = amount > 0 ? (totalRefundAmount * 100) / (amount - feeAmount) : 0
  const formattedPercent = formatToFixedNumber({ value: percent })
  return locale === 'ar' ? `%${formattedPercent}` : `${formattedPercent}%`
}
