import { FC } from 'react'
import { ContentContainer } from '@/root/components/shared'
import { AppLayout, RefundCard } from '@/root/modules/admaritime'
import { useCustomRouter, useGetTranslations } from '@/root/shared-hooks'
import { Banner } from '@/root/ui/admaritime'
import type { TicketPageProps } from '@/pages/ticket/[reservationToken]'
import { useRefund } from '@/root/shared-hooks/refund/useRefund'
import { InfoMessageContainer } from '@/root/components/admaritime'
import Loader from '@/root/ui/admaritime/Loader/Loader'

export const RefundPage: FC<TicketPageProps> = ({ reservation: staticReservation }) => {
  const { getTranslation } = useGetTranslations()

  const { refundProps, isLoading, error } = useRefund(staticReservation)
  const { push, locale = 'en' } = useCustomRouter()
  if (error) {
    return (
      <AppLayout>
        <div className="flex justify-center rounded-sm md:my-10 md:p-8">
          <InfoMessageContainer
            body={getTranslation('ticketReservationNotFoundError')}
            ButtonText={getTranslation('ticketReservationNotFoundButton')}
            onClick={() => push(`/${locale}`)}
          />
        </div>
      </AppLayout>
    )
  }

  if (isLoading) {
    return (
      <AppLayout>
        <ContentContainer>
          <div className="flex min-h-screen items-center justify-center py-40">
            <Loader />
          </div>
        </ContentContainer>
      </AppLayout>
    )
  }

  if (refundProps) {
    return (
      <AppLayout>
        <ContentContainer>
          <div className="flex flex-col gap-10 rounded-sm bg-white px-4 pb-14 pt-10 md:my-10 md:px-6 md:shadow-sm">
            <h1 className="text-primary-base text-center text-2xl font-normal tracking-wider">
              {getTranslation('cancelAndRemoveTicket')} {refundProps.reservationId}
            </h1>
            <Banner body={getTranslation('refundWarningText')} variant="info" />
            <RefundCard {...refundProps} />
          </div>
        </ContentContainer>
      </AppLayout>
    )
  }
}

export default RefundPage
