import { FC } from 'react'
import { useRouter } from 'next/router'
import CustomerPageLayout from './CustomerPageLayout'
import { CustomerDetails } from '@/root/modules/admaritime'

const CustomerDetailsPage: FC = () => {
  const router = useRouter()
  const showDetails = router.pathname.includes('details')

  return <CustomerPageLayout>{showDetails && <CustomerDetails />}</CustomerPageLayout>
}

export default CustomerDetailsPage
