import { FC, useEffect } from 'react'
import { ContentContainer } from '@/root/components/shared'
import { AppLayout, PrintedTicket, TicketActionButtons, TicketTerms } from '@/root/modules/admaritime'
import { useCustomRouter, useGetTranslations, usePrintedTicketData } from '@/root/shared-hooks'
import { Banner } from '@/root/ui/admaritime'
import { TicketPageProps } from '@/pages/ticket/[reservationToken]'
import { InfoMessageContainer } from '@/root/components/admaritime'
import Loader from '@/root/ui/admaritime/Loader/Loader'

const TicketPage: FC<TicketPageProps> = ({ reservation: staticReservation }) => {
  const { reload, push, locale = 'en' } = useCustomRouter()
  const { getTranslation } = useGetTranslations()
  const { reservation, error, isLoading } = usePrintedTicketData(staticReservation)

  useEffect(() => {
    if (reservation?.status === 'TEMP') {
      push('/')
    }
  }, [push, reservation?.status])

  if (isLoading) {
    return (
      <AppLayout>
        <ContentContainer>
          <div className="flex justify-center py-40">
            <Loader />
          </div>
        </ContentContainer>
      </AppLayout>
    )
  }

  if (error) {
    return (
      <AppLayout>
        <ContentContainer>
          <div className="flex justify-center py-40">
            <Banner
              title={getTranslation('ticketBannerErrorTitle')}
              body={getTranslation('ticketBannerErrorBody')}
              variant="warning"
            />
          </div>
        </ContentContainer>
      </AppLayout>
    )
  }

  if (reservation) {
    const { status } = reservation

    if (status === 'DL' || status === 'OFFER') {
      return (
        <AppLayout>
          <div className="flex justify-center rounded-sm md:my-10 md:p-8">
            {status === 'DL' && (
              <InfoMessageContainer
                body={getTranslation('ticketReservationNotFoundError')}
                ButtonText={getTranslation('ticketReservationNotFoundButton')}
                onClick={() => push(`/${locale}`)}
              />
            )}
            {status === 'OFFER' && (
              <InfoMessageContainer
                title={getTranslation('paymentPendingHeading')}
                body={getTranslation('paymentPendingText')}
                ButtonText={getTranslation('paymentPendingReloadButton')}
                onClick={() => reload()}
              />
            )}
          </div>
        </AppLayout>
      )
    }

    if (status === 'OK' || status === 'CN') {
      const isCancelled = reservation.isRoundTrip
        ? reservation.there.isRefund && reservation.back.isRefund
        : reservation.there.isRefund

      return (
        <AppLayout>
          <ContentContainer>
            <div className="my-10 rounded-sm bg-white p-3 shadow-sm md:p-8">
              {!isCancelled && (
                <>
                  <Banner
                    title={getTranslation('ticketBannerTitle')}
                    body={getTranslation('ticketBannerBody')}
                    variant="success"
                  />
                  {reservation.reservationId && reservation.token && (
                    <TicketActionButtons
                      className="my-7"
                      reservationId={reservation.reservationId}
                      reservationToken={reservation.token}
                    />
                  )}
                </>
              )}

              <PrintedTicket ticketData={reservation} />
              <TicketTerms />
            </div>
          </ContentContainer>
        </AppLayout>
      )
    }
  }
  return null
}

export default TicketPage
