import React, { FC, useEffect, useState } from 'react'
import { VesselCard } from '../VesselCard/VesselCard'
import { Carousel } from '@/root/components/admaritime/Carousel/components/Carousel/Carousel'
import { ADMVessel } from '@/root/shared-types'
import cn from 'classnames'

interface FleetSectionProps {
  vessels: ADMVessel[]
  title?: string
  id?: number
}

const BREAKPOINT_LARGE = 1052

export const FleetSection: FC<FleetSectionProps> = ({ vessels, title }) => {
  const [slidesOnScreen, setSlidesOnScreen] = useState(1)

  const updateSlidesOnScreen = () => {
    const slidesCount = window.innerWidth >= BREAKPOINT_LARGE ? 2 : 1
    setSlidesOnScreen(slidesCount)
  }

  useEffect(() => {
    updateSlidesOnScreen()
    window.addEventListener('resize', updateSlidesOnScreen)

    return () => {
      window.removeEventListener('resize', updateSlidesOnScreen)
    }
  }, [])

  return (
    <div
      className={cn(
        'mx-auto flex flex-col gap-6 items-center w-[340px] xxs:w-[390px] xs:w-[440px]',
        { 'sm:w-[570px]': slidesOnScreen === 1 },
        { 'lg:w-[1020px]': slidesOnScreen === 2 }
      )}
    >
      <h1 className="text-2xl font-bold text-primary-base">{title}</h1>
      <Carousel
        slidesOnScreen={slidesOnScreen}
        slides={vessels.map((vessel) => (
          <VesselCard {...vessel} key={vessel.slug} />
        ))}
      />
    </div>
  )
}
