import React from 'react'
import { BookTicketIcon } from '@/root/ui/shared/icons/BookTicketIcon'
import { useGetTranslations } from '@/root/shared-hooks'

const BookTicketBadge = () => {
  const { getTranslation } = useGetTranslations()
  return (
    <div className="-mb-5 flex w-full justify-center gap-1 lg:justify-start">
      <BookTicketIcon className="size-9 ps-1" />
      <p className="text-primary-base pt-1 text-base font-bold">{getTranslation('bookTicketBadgeTitle')}</p>
    </div>
  )
}

export default BookTicketBadge
