import type { TicketPageProps } from '@/pages/ticket/[reservationToken]'
import { FC } from 'react'
import { useCustomRouter, useGetTranslations, usePrintedTicketData } from '@/root/shared-hooks'
import { AppLayout, BookTicket } from '@/root/modules/admaritime'
import { ContentContainer } from '@/root/components/shared'
import { BookingProgressBar, BookingProgressBarContainer } from '@/root/modules/shared'
import { useErrorBanner } from '@/root/shared-hooks/useErrorBanner/useErrorBanner'
import { Banner } from '@/root/ui/bluewave'
import Loader from '@/root/ui/admaritime/Loader/Loader'

export const ConfirmTicketsPage: FC<TicketPageProps> = ({ reservation: staticReservation, privatePersonForm }) => {
  const { reservation, error, isLoading } = usePrintedTicketData(staticReservation)
  const { errorBody } = useErrorBanner()
  const { getTranslation } = useGetTranslations()
  const { query } = useCustomRouter()
  const { errorHandler } = useErrorBanner()

  if (error) {
    return (
      <AppLayout>
        <h1>Error page</h1>
      </AppLayout>
    )
  }
  if (isLoading) {
    return (
      <AppLayout>
        <ContentContainer>
          <div className="flex justify-center py-40">
            <Loader />
          </div>
        </ContentContainer>
      </AppLayout>
    )
  }

  if (reservation?.status === 'OFFER') {
    if (query.error === 'PAYMENT_STATUS_FAILURE' && errorHandler) {
      errorHandler({
        error: new Error(),
        time: new Date(),
        message: getTranslation('confirmationBookingPaymentProcessError'),
      })
    }

    return (
      <AppLayout>
        <ContentContainer>
          <div className="my-10 flex flex-col justify-center gap-6 bg-white pb-14">
            <BookingProgressBarContainer>
              <BookingProgressBar maxReachedStep="confirm" />
            </BookingProgressBarContainer>
            {errorBody && (
              <Banner title={getTranslation('ticketBannerErrorTitle')} variant="error" className="-mt-6 border-0">
                <p className="whitespace-pre-line font-normal">{errorBody}</p>
              </Banner>
            )}
            {reservation && <BookTicket ticketData={reservation} privatePersonForm={privatePersonForm} />}
          </div>
        </ContentContainer>
      </AppLayout>
    )
  }
}
