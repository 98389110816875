import { AppLayout, NewsSection, RouteButtonsSection } from '@/root/modules/admaritime'
import { StaticContentProvider } from '@/root/shared-providers'
import { PageProps } from '@/root/shared-types'
import { FC } from 'react'
import { ContentContainer } from '@/root/ui/admaritime'
import { FleetSection } from '@/root/modules/admaritime/Fleet/components/FleetSection/FleetSection'
import { RouteSearchBar } from '@/root/modules/admaritime'
import BookTicketBadge from '@/root/modules/admaritime/AppLayout/components/BookTicketBadge/BookTicketBadge'

const MainPage: FC<PageProps> = ({
  footerItems,
  menuItems,
  news,
  siteInfos,
  fleet,
  sailPackages,
  routes,
  sailPackagesGroups,
}) => {
  return (
    <StaticContentProvider
      value={{
        staticFooterItems: footerItems,
        staticMenuItems: menuItems,
      }}
    >
      <AppLayout>
        <ContentContainer disableBackground className="mt-0 xl:px-5">
          <BookTicketBadge />
          {sailPackages && <RouteSearchBar sailPackages={sailPackages} />}

          {Boolean(sailPackagesGroups?.length) && sailPackagesGroups && sailPackages && routes && (
            <RouteButtonsSection routes={routes} sailPackagesGroups={sailPackagesGroups} />
          )}
          {news && siteInfos && <NewsSection news={news} siteInfos={siteInfos} />}
          <FleetSection vessels={fleet?.vessels || []} title={fleet?.subject} />
        </ContentContainer>
      </AppLayout>
    </StaticContentProvider>
  )
}

export default MainPage
