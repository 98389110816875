import { FC } from 'react'
import { AppLayout, DefaultPage, NewsPage, SchedulePage, TariffsPage } from '@/root/modules/admaritime'
import { useGetPageContent } from '@/root/shared-hooks/sales-static/usePages'
import { News, Page, Route, Slug, Tariff } from '@/root/shared-types'
import { SiteMap } from '@/root/modules/admaritime'
import { useCustomRouter } from '@/root/shared-hooks'

export const ContentPage: FC = () => {
  const {
    query: { page },
  } = useCustomRouter()

  const { pageContent } = useGetPageContent(page as Slug, 'admaritime')

  const ContentDefiner = (slug: Slug) => {
    switch (slug) {
      case 'sitemap':
        return <SiteMap />
      case 'news':
        return <NewsPage news={(pageContent.page || []) as News[]} />
      case 'tariffs':
        return <TariffsPage tariffs={pageContent.page as Tariff[]} />
      case 'schedule':
        return pageContent.page && <SchedulePage routes={pageContent.page as Route[]} />
      default:
        return <DefaultPage {...(pageContent.page as Page)} />
    }
  }

  return <AppLayout>{ContentDefiner(page as Slug)}</AppLayout>
}
