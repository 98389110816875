import { FC } from 'react'
import { useRouter } from 'next/router'
import CustomerPageLayout from './CustomerPageLayout'
import { useGetReservationByUser } from '@/root/shared-hooks'
import FilterWithReservations from '@/root/modules/admaritime/CustomerInformation/FilterWithReservations'
import { Loader } from '@/root/ui/shared'

interface CustomerReservationProps {
  customerId: number
}

const CustomerReservation: FC<CustomerReservationProps> = ({ customerId }) => {
  const router = useRouter()
  const showReservations = router.pathname.includes('reservations')
  const { data, isLoading } = useGetReservationByUser({ customerId })

  if (data) {
    return (
      <CustomerPageLayout>
        {showReservations && <FilterWithReservations reservations={data.content ?? []} />}
      </CustomerPageLayout>
    )
  }
  if (isLoading) {
    return (
      <CustomerPageLayout>
        <Loader />
      </CustomerPageLayout>
    )
  }
}

export default CustomerReservation
