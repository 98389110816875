import MainPage from './MainPage/MainPage'
import RegisterAndLoginPage from './RegisterAndLoginPage/RegisterAndLoginPage'
import TicketPage from './TicketPage/TicketPage'
import RefundPage from './RefundPage/RefundPage'
import RefundResultPage from './RefundResultPage/RefundResultPage'
import { UserManualPage } from '@/root/pages/admaritime/UserManualPage/UserManualPage'
import { ContentPage } from '@/root/pages/admaritime/ContentPage/ContentPage'
import { ConfirmTicketsPage } from '@/root/pages/admaritime/ConfirmTicketsPage/ConfirmTicketsPage'
import CustomerDetailsPage from './CustomerPage/CustomerDetailsPage'
import CustomerReservation from './CustomerPage/CustomerReservation'
import CustomerPassengersAndGroupsPage from './CustomerPage/CustomerPassengersAndGroups'

export {
  MainPage,
  RegisterAndLoginPage,
  TicketPage,
  RefundPage,
  RefundResultPage,
  UserManualPage,
  ContentPage,
  ConfirmTicketsPage,
  CustomerDetailsPage,
  CustomerReservation,
  CustomerPassengersAndGroupsPage,
}
